import { Form, Input, Modal, Table, Space, notification, Upload, message, Image } from 'antd'
import { useContext, useEffect, useState } from 'react'
import {
  PlusCircleTwoTone, ExclamationCircleOutlined,
  CloudDownloadOutlined, PlusOutlined, LoadingOutlined
} from '@ant-design/icons'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete, Put } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import UploadBanners from '../UploadBanners'

const { Column } = Table
const { confirm } = Modal

const ManageLanguages = () => {
  const { token, apiUrl } = useContext(Context)

  const [languages, setLanguages] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [modalType, setModalType] = useState("")
  const [languageId,setLanguageId] = useState()

  const [form] = Form.useForm()


  useEffect(() => {
    Fetch(`${apiUrl}/languages`, token, (result) => {
      const languages = convertIdToKey(result.languages)
      setLanguages(languages)
    })
  }, [apiUrl, token, counter])

  const showEditModal = (record) => {
    setModalType("Edit")
    setLanguageId(record.key)
    form.setFieldsValue(record)
    setModalVisible(true)
  }

  const handleSubmit = async () => {
    try {
      setConfirmLoading(true)
      await form.validateFields()
      const fields = await form.validateFields()
      const fn = modalType === 'Add' ? Post : Put
        fn(
        modalType === 'Add' ? `${apiUrl}/languages` : `${apiUrl}/languages/${languageId}`,
        token,
        fields,
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: modalType === 'Add' ? 'Language created' : 'Language updated'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setCounter(counter + 1)
        },
        () => {
          notification.error({
            message: 'Add Language Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Add Language Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  const confirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this language?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/languages/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Succes',
                description: 'Language deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting language',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  return (
    <div className="manage-genres-wrapper">
      <Table dataSource={languages}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        <Column title="Native Name" dataIndex="nativeName" key="nativeName" align="center" />
        <Column title="Card Image" dataIndex={"cardImage"} key="cardImage" align='center'
          render={(_, record) =>
            record.cardImage ?
              <Image src={record.cardImage} width={'200px'} height={'100px'}></Image> : null
          } />
        <Column
          title="Actions"
          key="actions"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showEditModal(record)}>Edit</a>
              <a onClick={() => confirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>
      <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-genre-btn" 
      onClick={() =>  {
        setModalVisible(true);
        setModalType('Add');
      }} />
      <CloudDownloadOutlined className="export-btn" onClick={() => handleExportClick(apiUrl, token, 'languages')} />
      <Modal
        title={`${modalType} Language`}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => {
            setModalVisible(false);
            form.resetFields(); }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder='Hindi' disabled={modalType !== 'Add'} />
          </Form.Item> 
          <Form.Item name="nativeName" label="Native Name" rules={[{ required: false }]}>
            <Input placeholder={"हिन्दी"} />
          </Form.Item>
          <Form.Item name="cardImage" label="Banner" rules={[{ required: false }]}>
            <UploadBanners name="cardImage" updateForm={form} />
          </Form.Item>

        </Form>
      </Modal>
    </div>
  )
}

export default ManageLanguages
