export const convertIdToKey = (listOfObjects) => {
    return listOfObjects.map(obj => {
        const { '_id': key, ...rest } = obj
        return { key, ...rest }
    })
}

export const removeUnpublished = (listOfObjects) => {
    return listOfObjects.filter(obj => {
        return obj.availability === 'perpetual'
    })
}

export const convertSubscriptions = (listOfObjects) => {
    return listOfObjects.map(obj => {
        const { subscriptions, ...rest } = obj
        if (subscriptions) {
            const reversedSubscriptions = subscriptions.reverse()
            const subscription = `${reversedSubscriptions[0].name} (${reversedSubscriptions[0].startDate} to ${reversedSubscriptions[0].endDate})`
            return { subscription, ...rest }
        } else {
            return { subscription: 'None', ...rest }
        }
    })
}

export const handleExportClick = (apiUrl, token, content) => {
    fetch(`${apiUrl}/exports/${content}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.status)
        }
        return response.blob()
    }).then(blob => {
        let file = window.URL.createObjectURL(blob)
        let tab = window.open()
        tab.location.href = file
    }).catch(err => {
        console.error(err)
    })
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const currencycodes = {
    "EUR": "\u20ac - Euro (EUR)", "INR": "\u20b9 - Indian Rupee (INR)",
    "SGD": "S$ - Singapore Dollar (SGD)", "USD": "$ - US Dollar (USD)",
    "AED": "\u062f.\u0625 - Emirati Dirham (AED)", "ALL": "Lek - Albanian Lek (ALL)",
    "AMD": "\u058f - Armenian Dram (AMD)", "ARS": "ARS - Argentine Peso (ARS)",
    "AUD": "A$ - Australian Dollar (AUD)", "AWG": "Afl. - Aruban or Dutch Guilder (AWG)",
    "BBD": "$ - Barbadian or Bajan Dollar (BBD)", "BDT": "\u09f3 - Bangladeshi Taka (BDT)",
    "BMD": "$ - Bermudian Dollar (BMD)", "BND": "BND - Bruneian Dollar (BND)",
    "BOB": "Bs - Bolivian Bol\u00edviano (BOB)", "BSD": "B$ - Bahamian Dollar (BSD)",
    "BWP": "P - Botswana Pula (BWP)", "BZD": "BZ$ - Belizean Dollar (BZD)",
    "CAD": "C$ - Canadian Dollar (CAD)", "CHF": "CHf - Swiss Franc (CHF)",
    "CNY": "\u00a5 - Chinese Yuan Renminbi (CNY)", "COP": "COL$ - Colombian Peso (COP)",
    "CRC": "\u20a1 - Costa Rican Colon (CRC)", "CUP": "$MN - Cuban Peso (CUP)",
    "CZK": "K\u010d - Czech Koruna (CZK)", "DKK": "DKK - Danish Krone (DKK)",
    "DOP": "RD$ - Dominican Peso (DOP)", "DZD": "\u062f.\u062c - Algerian Dinar (DZD)",
    "EGP": "E\u00a3 - Egyptian Pound (EGP)", "ETB": "\u1265\u122d - Ethiopian Birr (ETB)",
    "FJD": "FJ$ - Fijian Dollar (FJD)", "GBP": "\u00a3 - British Pound (GBP)",
    "GHS": "GH\u20b5 - Ghanaian Cedi (GHS)", "GIP": "GIP - Gibraltar Pound (GIP)",
    "GMD": "D - Gambian Dalasi (GMD)", "GTQ": "Q - Guatemalan Quetzal (GTQ)",
    "GYD": "G$ - Guyanese Dollar (GYD)", "HKD": "HK$ - Hong Kong Dollar (HKD)",
    "HNL": "HNL - Honduran Lempira (HNL)", "HRK": "kn - Croatian Kuna (HRK)",
    "HTG": "G - Haitian Gourde (HTG)", "HUF": "Ft - Hungarian Forint (HUF)",
    "IDR": "Rp - Indonesian Rupiah (IDR)", "ILS": "\u20aa - Israeli Shekel (ILS)",
    "JMD": "J$ - Jamaican Dollar (JMD)", "KES": "Ksh - Kenyan Shilling (KES)",
    "KGS": "\u041b\u0432 - Kyrgyzstani Som (KGS)", "KHR": "\u17db - Cambodian Riel (KHR)",
    "KYD": "CI$ - Caymanian Dollar (KYD)", "KZT": "\u20b8 - Kazakhstani Tenge (KZT)",
    "LAK": "\u20ad - Lao Kip (LAK)", "LKR": "\u0dbb\u0dd4 - Sri Lankan Rupee (LKR)",
    "LRD": "L$ - Liberian Dollar (LRD)", "LSL": "LSL - Basotho Loti (LSL)",
    "MAD": "\u062f.\u0645. - Moroccan Dirham (MAD)", "MDL": "MDL - Moldovan Leu (MDL)",
    "MKD": "\u0434\u0435\u043d - Macedonian Denar (MKD)", "MMK": "MMK - Burmese Kyat (MMK)",
    "MNT": "\u20ae - Mongolian Tughrik (MNT)", "MOP": "MOP$ - Macau Pataca (MOP)",
    "MUR": "\u20a8 - Mauritian Rupee (MUR)", "MVR": "Rf - Maldivian Rufiyaa (MVR)",
    "MWK": "MK - Malawian Kwacha (MWK)", "MXN": "Mex$ - Mexican Peso (MXN)",
    "MYR": "RM - Malaysian Ringgit (MYR)", "NAD": "N$ - Namibian Dollar (NAD)",
    "NGN": "\u20a6 - Nigerian Naira (NGN)", "NIO": "NIO - Nicaraguan Cordoba (NIO)",
    "NOK": "NOK - Norwegian Krone (NOK)", "NPR": "\u0930\u0942 - Nepalese Rupee (NPR)",
    "NZD": "NZ$ - New Zealand Dollar (NZD)", "PEN": "S/ - Peruvian Sol (PEN)",
    "PGK": "PGK - Papua New Guinean Kina (PGK)", "PHP": "\u20b1 - Philippine Peso (PHP)",
    "PKR": "\u20a8 - Pakistani Rupee (PKR)", "QAR": "QR - Qatari Riyal (QAR)",
    "RUB": "\u20bd - Russian Ruble (RUB)", "SAR": "SR - Saudi Arabian Riyal (SAR)",
    "SCR": "SRe - Seychellois Rupee (SCR)", "SEK": "SEK - Swedish Krona (SEK)",
    "SLL": "Le - Sierra Leonean Leone (SLL)", "SOS": "Sh.so. - Somali Shilling (SOS)",
    "SSP": "SS\u00a3 - South Sudanese Pound (SSP)", "SVC": "\u20a1 - Salvadoran Colon (SVC)",
    "SZL": "E - Swazi Lilangeni (SZL)", "THB": "\u0e3f - Thai Baht (THB)",
    "TTD": "TT$ - Trinidadian Dollar (TTD)", "TZS": "Sh - Tanzanian Shilling (TZS)",
    "UYU": "$U - Uruguayan Peso (UYU)", "UZS": "so'm - Uzbekistani Som (UZS)",
    "YER": "\ufdfc - Yemeni Rial (YER)", "ZAR": "R - South African Rand (ZAR)"
}


export const countryCodes = {
    'AD': 'Andorra', 'AE': 'United Arab Emirates', 'AF': 'Afghanistan', 'AG': 'Antigua And Barbuda', 'AI': 'Anguilla', 'AL': 'Albania',
    'AM': 'Armenia', 'AN': 'Netherlands Antilles', 'AO': 'Angola', 'AQ': 'Antarctica', 'AR': 'Argentina', 'AS': 'American Samoa', 'AT': 'Austria',
    'AU': 'Australia', 'AW': 'Aruba', 'AX': 'Aland Islands', 'AZ': 'Azerbaijan', 'BA': 'Bosnia And Herzegovina', 'BB': 'Barbados', 'BD': 'Bangladesh',
    'BE': 'Belgium', 'BF': 'Burkina Faso', 'BG': 'Bulgaria', 'BH': 'Bahrain', 'BI': 'Burundi', 'BJ': 'Benin', 'BL': 'Saint Barthelemy',
    'BM': 'Bermuda', 'BN': 'Brunei Darussalam', 'BO': 'Bolivia', 'BR': 'Brazil', 'BS': 'Bahamas', 'BT': 'Bhutan', 'BV': 'Bouvet Island',
    'BW': 'Botswana', 'BY': 'Belarus', 'BZ': 'Belize', 'CA': 'Canada', 'CC': 'Cocos (Keeling) Islands', 'CD': 'Congo, Democratic Republic',
    'CF': 'Central African Republic', 'CG': 'Congo', 'CH': 'Switzerland', 'CI': 'Cote D"Ivoire', 'CK': 'Cook Islands', 'CL': 'Chile', 'CM': 'Cameroon',
    'CN': 'China', 'CO': 'Colombia', 'CR': 'Costa Rica', 'CU': 'Cuba', 'CV': 'Cape Verde', 'CX': 'Christmas Island', 'CY': 'Cyprus',
    'CZ': 'Czech Republic', 'DE': 'Germany', 'DJ': 'Djibouti', 'DK': 'Denmark', 'DM': 'Dominica', 'DO': 'Dominican Republic', 'DZ': 'Algeria',
    'EC': 'Ecuador', 'EE': 'Estonia', 'EG': 'Egypt', 'EH': 'Western Sahara', 'ER': 'Eritrea', 'ES': 'Spain', 'ET': 'Ethiopia', 'FI': 'Finland',
    'FJ': 'Fiji', 'FK': 'Falkland Islands (Malvinas)', 'FM': 'Micronesia, Federated States Of', 'FO': 'Faroe Islands', 'FR': 'France',
    'GA': 'Gabon', 'GB': 'United Kingdom', 'GD': 'Grenada', 'GE': 'Georgia', 'GF': 'French Guiana', 'GG': 'Guernsey', 'GH': 'Ghana',
    'GI': 'Gibraltar', 'GL': 'Greenland', 'GM': 'Gambia', 'GN': 'Guinea', 'GP': 'Guadeloupe', 'GQ': 'Equatorial Guinea', 'GR': 'Greece',
    'GS': 'South Georgia And Sandwich Isl.', 'GT': 'Guatemala', 'GU': 'Guam', 'GW': 'Guinea-Bissau', 'GY': 'Guyana', 'HK': 'Hong Kong',
    'HM': 'Heard Island & Mcdonald Islands', 'HN': 'Honduras', 'HR': 'Croatia', 'HT': 'Haiti', 'HU': 'Hungary', 'ID': 'Indonesia', 'IE': 'Ireland',
    'IL': 'Israel', 'IM': 'Isle Of Man', 'IN': 'India', 'IO': 'British Indian Ocean Territory', 'IQ': 'Iraq', 'IR': 'Iran, Islamic Republic Of',
    'IS': 'Iceland', 'IT': 'Italy', 'JE': 'Jersey', 'JM': 'Jamaica', 'JO': 'Jordan', 'JP': 'Japan', 'KE': 'Kenya', 'KG': 'Kyrgyzstan',
    'KH': 'Cambodia', 'KI': 'Kiribati', 'KM': 'Comoros', 'KN': 'Saint Kitts And Nevis', 'KP': 'North Korea', 'KR': 'Korea', 'KW': 'Kuwait', 'KY': 'Cayman Islands',
    'KZ': 'Kazakhstan', 'LA': 'Lao People"s Democratic Republic', 'LB': 'Lebanon', 'LC': 'Saint Lucia', 'LI': 'Liechtenstein', 'LK': 'Sri Lanka', 'LR': 'Liberia',
    'LS': 'Lesotho', 'LT': 'Lithuania', 'LU': 'Luxembourg', 'LV': 'Latvia', 'LY': 'Libyan Arab Jamahiriya', 'MA': 'Morocco', 'MC': 'Monaco',
    'MD': 'Moldova', 'ME': 'Montenegro', 'MF': 'Saint Martin', 'MG': 'Madagascar', 'MH': 'Marshall Islands', 'MK': 'Macedonia',
    'ML': 'Mali', 'MM': 'Myanmar', 'MN': 'Mongolia', 'MO': 'Macao', 'MP': 'Northern Mariana Islands', 'MQ': 'Martinique',
    'MR': 'Mauritania', 'MS': 'Montserrat', 'MT': 'Malta', 'MU': 'Mauritius', 'MV': 'Maldives', 'MW': 'Malawi', 'MX': 'Mexico',
    'MY': 'Malaysia', 'MZ': 'Mozambique', 'NA': 'Namibia', 'NC': 'New Caledonia', 'NE': 'Niger', 'NF': 'Norfolk Island', 'NG': 'Nigeria',
    'NI': 'Nicaragua', 'NL': 'Netherlands', 'NO': 'Norway', 'NP': 'Nepal', 'NR': 'Nauru', 'NU': 'Niue', 'NZ': 'New Zealand', 'OM': 'Oman',
    'PA': 'Panama', 'PE': 'Peru', 'PF': 'French Polynesia', 'PG': 'Papua New Guinea', 'PH': 'Philippines', 'PK': 'Pakistan', 'PL': 'Poland',
    'PM': 'Saint Pierre And Miquelon', 'PN': 'Pitcairn', 'PR': 'Puerto Rico', 'PS': 'Palestinian Territory, Occupied', 'PT': 'Portugal',
    'PW': 'Palau', 'PY': 'Paraguay', 'QA': 'Qatar', 'RE': 'Reunion', 'RO': 'Romania', 'RS': 'Serbia', 'RU': 'Russian Federation',
    'RW': 'Rwanda', 'SA': 'Saudi Arabia', 'SB': 'Solomon Islands', 'SC': 'Seychelles', 'SD': 'Sudan', 'SE': 'Sweden', 'SG': 'Singapore',
    'SH': 'Saint Helena', 'SI': 'Slovenia', 'SJ': 'Svalbard And Jan Mayen', 'SK': 'Slovakia', 'SL': 'Sierra Leone', 'SM': 'San Marino',
    'SN': 'Senegal', 'SO': 'Somalia', 'SR': 'Suriname', 'ST': 'Sao Tome And Principe', 'SV': 'El Salvador', 'SY': 'Syrian Arab Republic',
    'SZ': 'Swaziland', 'TC': 'Turks And Caicos Islands', 'TD': 'Chad', 'TF': 'French Southern Territories', 'TG': 'Togo', 'TH': 'Thailand',
    'TJ': 'Tajikistan', 'TK': 'Tokelau', 'TL': 'Timor-Leste', 'TM': 'Turkmenistan', 'TN': 'Tunisia', 'TO': 'Tonga', 'TR': 'Turkey',
    'TT': 'Trinidad And Tobago', 'TV': 'Tuvalu', 'TW': 'Taiwan', 'TZ': 'Tanzania', 'UA': 'Ukraine', 'UG': 'Uganda',
    'UM': 'United States Outlying Islands', 'US': 'United States', 'UY': 'Uruguay', 'UZ': 'Uzbekistan', 'VA': 'Holy See (Vatican City State)',
    'VC': 'Saint Vincent And Grenadines', 'VE': 'Venezuela', 'VG': 'Virgin Islands, British', 'VI': 'Virgin Islands, U.S.', 'VN': 'Vietnam',
    'VU': 'Vanuatu', 'WF': 'Wallis And Futuna', 'WS': 'Samoa', 'YE': 'Yemen', 'YT': 'Mayotte', 'ZA': 'South Africa', 'ZM': 'Zambia', 'ZW': 'Zimbabwe'
}
