import { useContext, useEffect, useState } from 'react'
import {
    DatePicker,
    Table,
    Space,
    Form,
    Input,
    Modal,
    Radio,
    Select,
    notification,
} from 'antd'
import { PlusCircleTwoTone, ExclamationCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import moment from 'moment'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Put, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import UploadBanners from '../UploadBanners/index'

const { Column } = Table
const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal

const ManageAlbums = () => {
    const { token, apiUrl } = useContext(Context)

    const [albums, setAlbums] = useState([])
    const [availability, setAvailability] = useState("perpetual")
    const [album, setAlbum] = useState()
    const [languages, setLanguages] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [modalType, setModalType] = useState("")
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [counter, setCounter] = useState(0)
    const [albumsMaster, setAlbumsMaster] = useState([])

    const [form] = Form.useForm()

    useEffect(() => {
        Fetch(`${apiUrl}/languages`, token, (result) => {
            setLanguages(result.languages)
        })
    }, [apiUrl, token])

    useEffect(() => {
        Fetch(`${apiUrl}/albums`, token, result => {
            const albums = convertIdToKey(result.albums)
            setAlbums(albums)
            setAlbumsMaster(albums)
        })
    }, [apiUrl, token, counter])

    const showEditModal = (id) => {
        setAlbum(id)
        setModalType("Edit")
        setModalVisible(true)
        Fetch(`${apiUrl}/albums/${id}`, token, result => {
            const { startDate, endDate, ...others } = result
            if (startDate && endDate) {
                form.setFieldsValue({ ...others, startDate: moment(startDate).utcOffset(startDate), endDate: moment(endDate).utcOffset(endDate) })
            } else if (startDate) {
                form.setFieldsValue({ ...others, startDate: moment(startDate).utcOffset(startDate) })
            } else {
                form.setFieldsValue(others)
            }
            setAvailability(others.availability)
        })
    }

    const handleSubmit = async () => {
        setConfirmLoading(true)
        if (modalType === 'Add') {
            try {
                const fields = await form.validateFields()
                Post(
                    `${apiUrl}/albums`,
                    token,
                    fields,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Success',
                                description: 'Album created'
                            })
                        }
                        form.resetFields()
                        setModalVisible(false)
                        setCounter(counter + 1)
                    },
                    () => {
                        notification.error({
                            message: 'Add Album Error',
                            description: 'Check console for more details'
                        })
                    }
                )
            } catch (err) {
                notification.error({
                    message: 'Add Album Error',
                    description: 'Required fields are incorrect/missing'
                })
            } finally {
                setConfirmLoading(false)
            }
        } else {
            try {
                const fields = await form.validateFields()
                Put(
                    `${apiUrl}/albums/${album}`,
                    token,
                    fields,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Success',
                                description: 'Album edited'
                            })
                        }
                        form.resetFields()
                        setModalVisible(false)
                        setCounter(counter + 1)
                    },
                    () => {
                        notification.error({
                            message: 'Edit Album Error',
                            description: 'Check console for more details'
                        })
                    }
                )
            } catch (err) {
                notification.error({
                    message: 'Edit Album Error',
                    description: 'Required fields are incorrect/missing'
                })
            } finally {
                setConfirmLoading(false)
            }
        }
    }

    const confirmDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete this album?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/albums/${id}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Album deleted successfully'
                            })
                            setCounter(counter + 1)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting album',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    const handleSearchChange = (albumId) => {
        Fetch(
            `${apiUrl}/albums/${albumId}`,
            token,
            (result) => {
                const albums = convertIdToKey([result])
                albums.forEach(a => {
                    if (typeof (a['genre']) === 'string') {
                        a['genre'] = [a['genre']]
                    }
                })
                setAlbums(albums)
            }
        )
    }

    return (
      <div className="manage-episodes-wrapper">
        <Select
          placeholder="Search Albums"
          showSearch
          allowClear
          onChange={handleSearchChange}
          onClear={() => setAlbums(albumsMaster)}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {albumsMaster.map((a) => (
            <Option value={a.key}>{a.title}</Option>
          ))}
        </Select>
        <Table dataSource={albums}>
          <Column title="Name" dataIndex="title" key="title" align="center" />
          <Column
            title="Language"
            dataIndex="language"
            key="language"
            align="center"
          />
          <Column
            title="Availability"
            dataIndex="availability"
            key="availability"
            align="center"
          />
          <Column title="Model" dataIndex="model" key="model" align="center" />
          <Column
            title="Actions"
            key="actions"
            align="center"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => showEditModal(record.key)}>Edit</a>
                <a onClick={() => confirmDelete(record.key)}>Delete</a>
              </Space>
            )}
          />
        </Table>

        <PlusCircleTwoTone
          twoToneColor="#FF0091"
          style={{ fontSize: '48px' }}
          className="add-series-btn"
          onClick={() => {
            setModalVisible(true);
            setModalType('Add');
          }}
        />
        <CloudDownloadOutlined
          className="export-btn"
          onClick={() => handleExportClick(apiUrl, token, 'albums')}
        />
        <Modal
          visible={modalVisible}
          title={modalType + ' Album'}
          onOk={handleSubmit}
          onCancel={() => setModalVisible(false)}
          confirmLoading={confirmLoading}
          maskClosable={false}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="language"
              label="Language"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose a language" showSearch>
                {languages.map((language) => (
                  <Option value={language.name}>{language.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="metaTitle"
              label="metaTitle"
              rules={[{ required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="metaDescription"
              label="metaDescription"
              rules={[{ required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="availability"
              label="Availability"
              rules={[{ required: true }]}
            >
              <Radio.Group onChange={(e) => setAvailability(e.target.value)}>
                <Radio value="perpetual">Perpetual</Radio>
                <Radio value="restricted">Restricted</Radio>
                <Radio value="unpublished">Unpublished</Radio>
              </Radio.Group>
            </Form.Item>
            {availability === 'restricted' && (
              <>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  className="form-item-selector"
                  rules={[{ required: true }]}
                >
                  <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                </Form.Item>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  className="form-item-selector"
                >
                  <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                </Form.Item>
              </>
            )}
            <Form.Item label="Model" name="model" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value="subscription">Subscription</Radio>
                <Radio value="free">Free</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="cardImage"
              label="Card Image"
              rules={[{ required: true }]}
            >
              <UploadBanners name={"cardImageAlbum"} type="albums" updateForm={form} />
            </Form.Item>
            <Form.Item
              name="detailImage"
              label="Detail Image"
              rules={[{ required: true }]}
            >
              <UploadBanners name={"detailImage"} type="albums" updateForm={form} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
}

export default ManageAlbums
